import React from 'react'
import {Row, Card, CardText, CardBody, CardTitle} from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => 
{
  const redwoodCity = <a href="https://www.google.com/maps/place/Redwood+City,+CA/" target="_blank"  rel="noopener noreferrer" >Redwood City, CA</a>
  const pomona = <a href="https://www.google.com/maps/place/Pomona,+CA/" target="_blank"  rel="noopener noreferrer" > Pomona, CA</a>
  const wilton = <a href="https://www.google.com/maps/place/Clay+Station+Rd,+Wilton,+CA" target="_blank"  rel="noopener noreferrer" > Sacramento, CA </a>
  const sanJose = <a href="https://www.google.com/maps/place/San+Jose,+CA" target="_blank"  rel="noopener noreferrer" >San Jose, CA</a>
  const mcDonalds = <a href="https://www.google.com/maps/place/McDonald's/@37.2364696,-121.8067441,17.25z/data=!4m13!1m7!3m6!1s0x808fcae48af93ff5:0xb99d8c0aca9f717b!2sSan+Jose,+CA!3b1!8m2!3d37.3382082!4d-121.8863286!3m4!1s0x808e2e2ee876c91d:0xfa6341b59c1b274b!8m2!3d37.2359838!4d-121.8033922" target="_blank" rel="noopener noreferrer">McDonalds</a>
  const kmart = <a href="https://www.google.com/maps/@37.0001891,-121.5590184,20.75z" target="_blank"  rel="noopener noreferrer" >Kmart</a>
  const schoolDistrict = <a href="https://www.google.com/maps/place/Campbell+Union+School+District/@37.2889481,-121.9484422,19.5z/data=!4m13!1m7!3m6!1s0x808e35275a1e95a1:0x778e9b3209cb639d!2s155+N+3rd+St,+Campbell,+CA+95008!3b1!8m2!3d37.2888855!4d-121.9477895!3m4!1s0x808e352757a576bf:0xc750c79a3ba7b4dd!8m2!3d37.2888736!4d-121.9476572" target="_blank"  rel="noopener noreferrer" >Bay Area school district</a>
  const cae = <a href="http://cyberactiveentertainment.com" target="_blank"  rel="noopener noreferrer" >Cyber Active Entertainment</a>
  
return (

  <Layout pageTitle="About me..." author="show">
    <SEO title="About" />
    <Row className="mb-4">
        <Card style={{minHeight: '100%'}}>
          <CardBody>
            <CardTitle>About Me</CardTitle>
            <CardText>
            <p>Elizabeth Blackmore was born in {redwoodCity} but lived in {pomona}, at the age of 5,
            at that time her family moved to a small country town near {wilton} and she grew up in a bucolic existence of cattle ranchers and orchard farmers.</p>
            <p>When she was 14 her family moved to {sanJose} and Elizabeth found herself living in the largest city she had ever known.</p>
            <p>She had always been ambitious and loved to work, She performed many baby sitting jobs and cleaned teachers homes until she was 16,
            she then started her first real job at {mcDonalds} and quickly worked her way up to a Shift Manager position.</p>
            <p>After high school she worked many jobs related to the accounting industry from the cash cage at {kmart},
            home financing and a company that sold computer parts world wide, there she worked her way up from receptionist to the company’s controller.</p>
            <p>She married the man of her dreams in 1985 and always lived by the teaching of her Mother ”that you can do whatever you want if you put your mind to it”.</p>
            <p>She seized an opportunity at a {schoolDistrict} as Senior Payroll Technician and was being groomed to take over for the Personal Director,
            when she had her son and then her career became the furthest thing from her mind as she set out to perform the most important and rewarding job of her life, to raise and homeschool her son. She loved the time she was able to spend with her son and homeschooled him from Kindergarten through 12th grade.</p>
            <p>He is now the owner of {cae} and the maker of her website and awesome video introductions.</p>
            <p>During the time she was homeschooling she also helped her husband start his business and 25 years later their family business is still going strong.</p>
            <p>At 56 years old she saw a video of the Moxi skate team street skating and decided to give it a try.</p>
            <p>She quickly learned that it was a passion of hers and loves the free floating feeling and skating in open spaces.</p>
            <p>She found she loved to work on her skates and researched everything she could about the skating industry.</p>
            <p>Her passion is to bring quad skating back and encourage everyone that it’s never too late to skate!</p>
            </CardText>
          </CardBody>
        </Card>
    </Row>
  </Layout>

)
}
export default AboutPage